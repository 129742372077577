import * as React from 'react';
import FileInput from './FileInput';

import { Field, reduxForm } from 'redux-form';
import Button from '@material-ui/core/Button';
import { TextField, Select, FormControl, InputLabel } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { STRING_CONSTANTS } from '../constants';
import { compose } from 'redux';
import { render } from 'react-dom';


class RenderSelect extends React.Component {
  state = {
    value: ''
  };

  render() {
    const props: any = this.props;

    return (
      <FormControl
        fullWidth={true}
      >
        <InputLabel>
          {props.label}
        </InputLabel>
        <Select
          value={this.state.value.length !== 0 ? this.state.value : props.input.value}
          onChange={(event: any) => {
            props.input.onChange(event.target.value);
            this.setState({ value: event.target.value });
          }}
        >
          {props.children}
        </Select>
      </FormControl>
    );
  }
}

class RenderTextField extends React.Component {
  state = {
    value: ''
  };

  render() {
    const props: any = this.props;
    return (
      <FormControl
        fullWidth={true}
      >
        <TextField
          onChange={(event: any) => {
            props.input.onChange(event.target.value);
            this.setState({ value: event.target.value });
          }}
          multiline={props.multiline ? props.multiline : false}
          value={this.state.value.length !== 0 ? this.state.value : props.input.value}
          label={props.label}
          inputProps={{
            onFocus: props.focus,
            onBlur: props.blur
          }}
        />
      </FormControl>
    );
  }
}

let AddTask: React.StatelessComponent<any> = (props) => {
  const { handleSubmit, reset, submitSucceeded } = props;
  if (!submitSucceeded) {
    return (
      <form
        onSubmit={handleSubmit}
        style={{ width: '30%', margin: '0 auto' }}
      >
        <Field
          name="identifier"
          fullWidth={true}
          component={RenderTextField}
          label="Task Identifier"
        />
        <br />
        <Field
          name="name"
          fullWidth={true}
          component={RenderTextField}

          label="Task Name"
        />
        <br />
        <Field
          name="metric"
          component={RenderSelect}
          label="Metric"
        >
          <MenuItem value="accuracy">Accuracy</MenuItem>
          <MenuItem value="f1">F1</MenuItem>
          <MenuItem value="ps">Pearson and Spearman</MenuItem>
          <MenuItem value="matthews">Matthews</MenuItem>
          <MenuItem value="multirc">MultiRC</MenuItem>
          <MenuItem value="record">ReCoRD</MenuItem>
          <MenuItem value="winogender">Winogender</MenuItem>
        </Field>
        <br />
        <Field
          name="weight"
          fullWidth={true}
          component={RenderTextField}
          type="number"
          label="Weight"
        />
        <br />
        <Field
          name="type"
          component={RenderSelect}
          label="Task Type"
        >
          <MenuItem value="primary">Primary</MenuItem>
          <MenuItem value="auxiliary">Auxiliary</MenuItem>
        </Field>
        <br />
        <Field
          name="auxiliaryType"
          component={RenderSelect}
          label="Auxiliary Task Type"
        >
          <MenuItem value="main">Main</MenuItem>
          <MenuItem value="category">Category</MenuItem>
          <MenuItem value="subcategory">SubCategory</MenuItem>
        </Field>
        <br />
        <Field
          name="downloadURL"
          fullWidth={true}
          component={RenderTextField}
          label="Download URL"
        />
        <Field
          name="infoURL"
          fullWidth={true}
          component={RenderTextField}
          label="Info URL"
        />
        <Field
          name="superglueVersion"
          fullWidth={true}
          component={RenderTextField}
          label="SuperGLUE Version"
        />
        <br />
        <br />
        <Field
          type="file"
          name="testJSONL"
          component={FileInput}
          label={STRING_CONSTANTS.SELECT_JSONL_LABEL}
          accept=".jsonl"
        />
        <br />
        <br />
        <br />
        <Button
          variant="contained"
          color="primary"
          type="submit"
        >
          Add Task
        </Button>
      </form>
    );
  } else {
    return (
      <div>
        <span>Upload Successful</span>
        <br />
        <Button
          variant="contained"
          color="primary"
          onClick={reset}
        >
          Reset
        </Button>
      </div>
    );
  }
};

export default compose(reduxForm({
  form: 'addTask'
}))(AddTask);

export {
  RenderSelect,
  RenderTextField
};