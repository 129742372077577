import { db } from '../firebase';
import { UserInfoDB, UserResultType, ResultType, StoreState, TaskResultType } from '../types';

type AllResultsFromDBType = {
  [key: string]: ResultType;
};

type InitialSingleUserResultsType = {
  allSubmissions: ResultType[];
  displayName: string;
  avatarImageUrl: string;
};

export type UserwiseCollectedResultsType = {
  [key: string]: InitialSingleUserResultsType
};

export function formatAndAddUsersToResults(results: AllResultsFromDBType, metadata: StoreState['metadata']) {
  const uniqueUsers: UserwiseCollectedResultsType = {};

  for (let key in results) {
    if (results.hasOwnProperty(key)) {
      const result = results[key];
      if (!result) {
        continue;
      }
      let superglueVersion = result.superglueVersion;

      if (typeof(superglueVersion) === 'undefined' || superglueVersion === null) {
        superglueVersion = 1.9;
      }

      if (superglueVersion.toFixed(1) !== metadata.version) {
        result.macroScore = -101;
      }

      if (!uniqueUsers[result.uploaderUid]) {
        uniqueUsers[result.uploaderUid] = {
          allSubmissions: [result],
          displayName: '',
          avatarImageUrl: ''
        };
      } else {
        uniqueUsers[result.uploaderUid].allSubmissions.push(result);
      }
    }
  }

  const promises: Promise<any>[] = [];

  Object.keys(uniqueUsers).forEach((userId) => {
    let dbPromise = db.ref().child(`users/${userId}/publicFields`)
      .once('value');
    let promise = new Promise((resolve, reject) => {
      dbPromise.then(
        (value) => resolve({ value, userId }),
        (error) => reject(error)
      );
    });
    promises.push(promise);
  });

  return Promise.all(promises)
    .then(values => values.filter(value => !(value instanceof Error)))
    .then(values => {
      values.forEach((value, key) => {
        const userInfo: UserInfoDB['publicFields'] = value.value.val();
        uniqueUsers[value.userId] = {
          ...uniqueUsers[value.userId],
          displayName: userInfo.displayName!,
          avatarImageUrl: userInfo.avatarImageUrl!
        };
      });

      return sortAndFormat(uniqueUsers, metadata);
    });
}

export function sortAndFormat(uniqueUsers: UserwiseCollectedResultsType, metadata: StoreState['metadata']): UserResultType[] {
  const finalUsersResultsSorted: UserResultType[] = [];

  Object.keys(uniqueUsers).forEach((key) => {
    const initialUserResults: InitialSingleUserResultsType = uniqueUsers[key];
    let allSubmissions: ResultType[] = initialUserResults.allSubmissions;

    allSubmissions.sort(sortSubmissionAccordingToScore);
    const maxScoreSubmission: ResultType = allSubmissions[0];
    allSubmissions.shift();

    const finalUserResult: UserResultType = {
      displayName: initialUserResults.displayName,
      avatarImageUrl: initialUserResults.avatarImageUrl,
      maxScore: maxScoreSubmission.macroScore,
      maxScoreSubmission,
      otherSubmissions: allSubmissions
    };

    finalUsersResultsSorted.push(finalUserResult);
  });

  finalUsersResultsSorted.sort(sortUsersAccordingToMaxScore);
  return finalUsersResultsSorted;
}

export function sortSubmissionAccordingToScore(a: ResultType,
  b: ResultType): number {
  if (a.macroScore > b.macroScore) {
    return -1;
  } else if (a.macroScore < b.macroScore) {
    return 1;
  } else {
    return 0;
  }
}

export function sortUsersAccordingToMaxScore(a: UserResultType,
  b: UserResultType): number {
  if (a.maxScore > b.maxScore) {
    return -1;
  } else if (a.maxScore < b.maxScore) {
    return 1;
  } else {
    return 0;
  }
}
