import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import * as React from 'react';
import { store } from '../stores/index';

const PrivateRoute: React.StatelessComponent<RouteProps> = ({ component, ...rest }) => {
  const renderFn = (Component?: any) => (props: RouteProps) => {
    if (!Component) {
      return null;
    }

    if (store.getState().userInfo.isAuthenticated) {
      return <Component {...props} />;
    }

    const redirectProps = {
      to: {
        pathname: '/login',
        state: { from: props.location },
      },
    };

    return <Redirect {...redirectProps} />;
  };

  return <Route {...rest} render={renderFn(component)} />;
};

export default PrivateRoute;
