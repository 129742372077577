import * as React from 'react';
import { StoreState } from '../types/index';
import { timeSince, getRoundedScore } from '../utils/general';
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow
} from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import ReactTooltip from 'react-tooltip';
import { STRING_CONSTANTS } from '../constants';
import { Link } from 'react-router-dom';
import { boldStyle, tableStyle, alignCenterStyle, fontIconUrlStyle, fontIconStyle } from '../styles';
const loading = require('../loading.svg');

interface Props {
  userInfo: StoreState['userInfo'];
  general: StoreState['general'];
  fetchUserSubmissions: (userId: string) => Promise<void>;
  match?: {
    params: {
      uid: string;
    }
  };
}

class Profile extends React.Component<Props, {}> {
  componentDidMount() {
    if (this.props.match && this.props.match.params.uid) {
      const userId = this.props.match.params.uid;
      this.props.fetchUserSubmissions(userId);
    }
  }

  getThStyle = () => {
    return {
      ...boldStyle,
      // backgroundColor: STRING_CONSTANTS.THEME[this.props.general.theme].BACKGROUND_COLOR
    };
  }

  getHeader = () => {
    const thStyle = this.getThStyle();
    return (
      <TableHead>
        <TableRow>
          <TableCell style={thStyle}>Model</TableCell>
          <TableCell style={thStyle}>Status</TableCell>
          <TableCell style={thStyle}>URL</TableCell>
          <TableCell style={thStyle}>Score</TableCell>
          <TableCell style={thStyle}>Created On</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  getRows = () => {
    return (
      <TableBody>
        {

          Object.keys(this.props.userInfo.submissions).map((key, index) => {
            const submission = this.props.userInfo.submissions[key];
            const isSuccessful = (typeof submission.successful === 'undefined' ?
              true : submission.successful
            );
            return (
              <TableRow
                key={key}
              >
                <TableCell style={alignCenterStyle}>
                  <Link to={'/submission/' + this.props.userInfo.uid + '/' + key}>
                    {submission.editable ? (submission.editable.name || '') : ''}
                  </Link>
                </TableCell>
                <TableCell style={alignCenterStyle}>
                  <ReactTooltip effect="solid" html={true} place="right" data-multiline={true} />
                  {isSuccessful ?
                    <Icon
                      className="fa fa-check"
                      style={{ ...fontIconStyle, color: 'green' }}
                    /> :
                    <Icon
                      data-tip={this.getFormattedMessage(submission.message)}
                      className="fa fa-exclamation-triangle"
                      style={{ ...fontIconStyle, color: '#d6685c' }}
                    />
                  }
                </TableCell>
                <TableCell style={alignCenterStyle}>
                  {submission.editable ?
                    (submission.editable.url && submission.editable.url.length > 0 ?
                      (<a href={submission.editable.url}>
                        <Icon className="fa fa-external-link" style={fontIconUrlStyle} />
                      </a>) : '') : ''}
                </TableCell>
                <TableCell style={alignCenterStyle}>
                  {isSuccessful ? getRoundedScore(submission.macroScore) : 'Failed'}
                </TableCell>
                <TableCell style={alignCenterStyle}>
                  {timeSince(submission.createdOn)}
                </TableCell>
              </TableRow>
            );
          })
        }
      </TableBody>
    );
  }

  getFormattedMessage = (message: string) => {
    if (!message) {
      message = '';
    }

    if (typeof(message) !== 'string') {
      message = 'Server Error occurred. Contact admins!'
    }
    return message.split('\n').join('<br/>');
  }

  getSubmissionsTable = () => {
    if (this.props.userInfo.isFetchingSubmissions) {
      return (
        <div>
          <p>Fetching your submissions...</p>
          <img src={loading} className="App-logo" alt="Loading" />
        </div>
      );
    } else if (Object.keys(this.props.userInfo.submissions).length === 0) {
      return (<div>No submissions as of now. Keep crunching!</div>);
    }

    const tableHeader = this.getHeader();
    const tableRows = this.getRows();
    return (
      <Table style={tableStyle}>
        {tableHeader}
        {tableRows}
      </Table>
    );
  }

  render() {
    return (
      <div className="profile-container">
        <h1>{this.props.userInfo.displayName}'s submissions</h1>
        {this.getSubmissionsTable()}
        <br />
      </div>
    );
  }
}

export default Profile;
