import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core';
import { introStyles } from '../styles';

const nyuLogo = require('../ml2_logo.png');
const uwLogo = require('../uw_nlp_logo.png');
const deepmindLogo = require('../deepmind_logo.png');
const fairLogo = require('../fair_logo.png');
const samsungLogo = require('../samsung_research.png');

const Intro: React.StatelessComponent<{}> = (props: any) => {
  return (
    <div className="App-intro">
      <Paper
        elevation={0}
        style={{
          backgroundColor: 'transparent'
        }}
      >
        <div className="App-intro-heading">
          <strong>
            <img
              title="SuperGLUE"
              alt="SuperGLUE logo"
              src="assets/images/superglue_logo.svg"
              className={props.classes.superglueLogo}
            />
          </strong>
        </div>
        <br />
        <Grid container className={props.classes.logoContainer}>
          <Grid item xs={12} sm={6} lg={6}>
            <Grid
              container
              className={[props.classes.logoContainer, props.classes.firstLogo].join(" ")}
            >
              <Grid item xs={8} sm={5}>
                <a className={props.classes.logo} href="https://wp.nyu.edu/ml2/" title="ML^2 NYU">
                  <img alt="ML^2 NYU" className={props.classes.logoImage} title="ML^2 NYU Logo" src={nyuLogo} />
                </a>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <Grid container className={props.classes.logoContainer}>
              <Grid item xs={8} sm={5}>
                <a className={props.classes.logo} href="https://www.cs.washington.edu/research/nlp" title="UW NLP">
                  <img alt="UW NLP" className={props.classes.logoImage} title="UW NLP Logo" src={uwLogo} />
                </a>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <Grid container className={props.classes.logoContainer}>
              <Grid item sm={3}>
              </Grid>
              <Grid item xs={10} sm={9}>
                <a
                  className={props.classes.logo}
                  href="https://research.fb.com/category/facebook-ai-research/"
                  title="Facebook AI Research"
                >
                  <img alt="Facebook AI Research" className={props.classes.logoImage} title="Facebook AI Research Logo" src={fairLogo} />
                </a>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <Grid container className={props.classes.logoContainer}>
              <Grid item xs={10} sm={6}>
                <a className={props.classes.logo} href="https://deepmind.com/" title="DeepMind">
                  <img alt="DeepMind" className={props.classes.logoImage} title="DeepMind Logo" src={deepmindLogo} />
                </a>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <Grid container className={props.classes.logoContainer}>
              <Grid item xs={10} sm={9}>
                <a
                  className={props.classes.logo}
                  href="https://research.samsung.com/"
                  title="Samsung Researfch"
                >
                  <img alt="Samsung Research" className={props.classes.logoImage} title="Samsung Research Logo" src={samsungLogo} />
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className="App-intro-content">
          In the last year, new models and methods for pretraining and transfer
          learning have driven striking performance improvements across a range of
          language understanding tasks. The GLUE benchmark, introduced one year ago,
          offered a single-number metric that summarizes progress on a diverse set of
          such tasks, but performance on the benchmark has recently come close to the
          level of non-expert humans, suggesting limited headroom for further research.
          <br />
          <br />
          We take into account the lessons learnt from original GLUE benchmark and present
          SuperGLUE, a new benchmark styled after GLUE with a new set of more difficult
          language understanding tasks, improved resources, and a new public
          leaderboard.
          <br />
          <br />
        </div>
        <a href="https://w4ngatang.github.io/static/papers/superglue.pdf" title="Paper">
          <Button variant="contained" color="primary" style={{ marginBottom: '1em' }}>Paper</Button>
        </a>
        &nbsp;
        <a href="https://jiant.info" title="Paper">
          <Button variant="contained" color="primary" style={{ marginBottom: '1em' }}>Starter Code</Button>
        </a>
        &nbsp;
        <a href="https://groups.google.com/forum/#!forum/glue-benchmark-discuss" title="Google Group">
          <Button variant="contained" color="primary" style={{ marginBottom: '1em' }}>Group</Button>
        </a>
        &nbsp;
        <Link to="/diagnostics">
          <Button variant="contained" color="primary" style={{ marginBottom: '1em' }}>Diagnostics</Button>
        </Link>
        <br />
        <br />
      </Paper>
      <br />
      <br />
    </div>
  );
};

export default withStyles(introStyles)(Intro);
