import * as React from 'react';
import Submit from './Submit';
import * as firebase from 'firebase';
import { db, storage } from '../firebase';
import { SubmissionError } from 'redux-form';
import { StoreState } from '../types';
import NotReady from './NotReady';
import { overflowHidden } from '../styles';
import { type } from 'os';
const loading = require('../loading.svg');

interface Props {
  userInfo: StoreState['userInfo'];
  submission: StoreState['submission'];
  doCanUploadCheck: () => void;
}

// TODO: Fix the reset check for twice submission
// TODO: Maybe test the files in zip have TSV for each task here itself.
class SubmitForm extends React.Component<Props, {}> {
  submit = (values: any) => {

    const valuesNeeded = ['name', 'modelDescription',
      'parameterDescription', 'file'];

    // Check for all fields
    valuesNeeded.forEach((item) => {
      if (typeof (values[item]) === 'undefined') {
        throw new SubmissionError({ _error: 'Missing ' + item });
      }
    });

    const submissionId = db.ref().child('superglue/submissions').push().key;
    if (!submissionId) {
      throw new SubmissionError({ _error: 'Connection to DB failed' });
    }
    return this.uploadZip(values, submissionId).then(
      () => this.addSubmissionToUser(submissionId).then(
        () => this.addSubmissionToDB(values, submissionId).then(
          () => console.log('Upload successful'),
          () => this.throwError('Add to DB failed')
        ),
        () => this.throwError('Add to DB failed')
      ),
      () => this.throwError('Upload failed')
    );
  }

  componentDidMount() {
    this.props.doCanUploadCheck();
  }

  throwError = (err: string) => {
    throw new SubmissionError({ _error: err });
  }

  isValidSubmission = () => {
    return this.props.userInfo.isAuthenticated;
  }

  addSubmissionToUser = (submissionId: string) => {
    const data = {
      createdOn: firebase.database.ServerValue.TIMESTAMP
    };

    const userSubmissionRef = db.ref().child(`users/${this.props.userInfo.uid}`)
      .child(`publicButAddable/superglue/submissions/${submissionId}`);

    return userSubmissionRef.set(data);
  }

  addSubmissionToDB = (values: any, submissionId: string) => {
    const storagePath: string = `superglue/submissions/${submissionId}.zip`;

    if (typeof(values.woFineTuningParameters) === 'undefined') {
      values.woFineTuningParameters = -1;
    }
    if (typeof(values.fineTunedParameters) === 'undefined') {
      values.fineTunedParameters = -1;
    }
    if (typeof(values.taskSpecificParameters) === 'undefined') {
      values.taskSpecificParameters = -1;
    }

    const uploadData = {
      uploaderUid: this.props.userInfo.uid,
      filePath: storagePath,
      name: values.name,
      url: values.url || '',
      public: !!values.public,
      modelDescription: values.modelDescription,
      parameterDescription: values.parameterDescription,
      woFineTuningParameters: parseInt(values.woFineTuningParameters, 10),
      fineTunedParameters: parseInt(values.fineTunedParameters, 10),
      taskSpecificParameters: parseInt(values.taskSpecificParameters, 10),
      createdOn: firebase.database.ServerValue.TIMESTAMP,
      isGraded: false
    };

    const submissionRef = db.ref().child(`superglue/submissions/${submissionId}`);

    return submissionRef.set(uploadData);
  }

  uploadZip = (values: any, submissionId: string) => {
    return new Promise((resolve, reject) => {
      if (!this.isValidSubmission()) {
        reject('You need to be authenticated to upload');
      }

      const zipFolderRef = storage.ref().child('superglue/submissions');
      const zipName = submissionId + '.zip';

      const zipRef = zipFolderRef.child(zipName);

      const metadata = {
        customMetadata: {
          uploaderUid: this.props.userInfo.uid,
          originalName: values.file.name,
          uploaderEmail: this.props.userInfo.email
        }
      } as firebase.storage.SettableMetadata;

      zipRef.put(values.file, metadata).then(
        (snapshot: firebase.storage.UploadTaskSnapshot) => {
          resolve(snapshot);
        },
        () => {
          reject('Zip upload failed');
        }
      );
    });
  }

  render() {
    if (!this.props.userInfo.isSiteReady) {
      return <NotReady />;
    }
    if (this.props.submission.isChecking) {
      return (
        <div style={overflowHidden}>
          <p>Checking whether you can upload</p>
          <img src={loading} className="App-logo" alt="Loading" />
        </div>
      );
    } else {
      if (this.props.submission.canUpload) {
        return (
          <Submit onSubmit={this.submit} />
        );
      } else {
        return (
          <div>You can't upload more than two times a day and six times a month.</div>
        );
      }
    }
  }
}

export default SubmitForm;
