import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { StoreState } from '../types/index';
import Profile from '../components/Profile';
import { UserSubmissionAction, fetchUserSubmissions } from '../actions';

const mapStateToProps = (state: StoreState) => ({
  userInfo: state.userInfo,
  general: state.general
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchUserSubmissions: (userId: string) => dispatch(fetchUserSubmissions(userId))
});

const ProfileContainer = connect(
  mapStateToProps,
  mapDispatchToProps)(Profile);

export default ProfileContainer;
