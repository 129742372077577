import { Reducer } from 'redux';
import { STRING_CONSTANTS } from '../constants';
import { TaskResultType } from '../types';

export function reduceReducers(...reducers: Reducer<any, any>[]) {
  return (previous: any, current: any) =>
    reducers.reduce(
      (p, r) => r(p, current),
      previous
    );
}

export function randomString() {
  return Math.random().toString(36).substring(7);
}

export function timeSince(param: string) {
  let timestamp: any = param;
  if (typeof param === 'string' || typeof param === 'number') {
    timestamp = new Date(param);
  }
  const now = new Date(),
    secondsPast = (now.getTime() - timestamp.getTime()) / 1000;
  if (secondsPast < 60) {
    return Math.floor(secondsPast) + ' sec ago';
  }
  if (secondsPast < 3600) {
    return Math.floor(secondsPast / 60) + ' min ago';
  }
  if (secondsPast <= 86400) {
    return Math.floor(secondsPast / 3600) + ' hours ago';
  } else {
    const day = timestamp.getDate();
    const month = timestamp.toDateString().match(/ [a-zA-Z]*/)[0]
      .replace(' ', '');
    const year = timestamp.getFullYear() === now.getFullYear() ? ''
      : ' ' + timestamp.getFullYear();
    return day + ' ' + month + year;
  }
}

export const getTheme = (theme: string) => {
  const themePallete = STRING_CONSTANTS.THEME[theme];
  return {
    palette: {
      primary1Color: themePallete.DARK_YELLOW,
      textColor: themePallete.YELLOW,
      canvasColor: themePallete.FADE_GREY,
      borderColor: themePallete.FADE_GREY_BORDER,
      accent1Color: themePallete.ACCENT_1_COLOR,
      accent3Color: themePallete.GREEN,
      disabledColor: themePallete.TONED_WHITE
    },
    toolbar: {
      // color: themePallete.GREEN,
      backgroundColor: themePallete.BLACK
    },
    appBar: {
      color: themePallete.BLACK,
      textColor: themePallete.DARK_YELLOW
    },
    raisedButton: {
      primaryColor: themePallete.RAISED_BUTTON_COLOR,
      fontWeight: 600
    },
    tabs: {
      backgroundColor: themePallete.TABS_COLOR,
      textColor: themePallete.TABS_TEXT_COLOR,
      selectedTextColor: themePallete.TABS_SELECTED_TEXT_COLOR
    },
    textField: {
      focusColor: themePallete.BLACK
    },
    checkbox: {
      checkedColor: themePallete.BLACK
    }
  };
};

export const getMetricFullName = (metric: string) => {
  if (metric === 'accuracy') {
    return 'Accuracy';
  } else if (metric === 'f1') {
    return 'F1 / Accuracy';
  } else if (metric === 'ps') {
    return 'Pearson-Spearman Corr';
  } else if (metric === 'matthews') {
    return 'Matthew\'s Corr';
  } else if (metric === 'multirc') {
    return 'F1a / EM';
  } else if (metric === 'avgF1') {
    return 'Avg. F1 / Accuracy';
  } else if (metric === 'record') {
    return 'F1 / Accuracy';
  } else if (metric === 'winogender') {
    return 'Gender Parity / Accuracy';
  } else {
    return 'Accuracy';
  }
};

export const getRoundedScore = (x: number) => {
  if (x < -100) {
    return '-';
  }
  if (x.toString() === '-') {
    return '-';
  }
  return (Math.round(x * 1000) / 10).toFixed(1);
};

export const getPrintableConfusion = (x: Array<Array<string>>) => {
  // let ret = x.map(first => first.join(' ')).join(']\n [');
  const labels = ['N', 'E'];
  let ret = x.map(first => first.map(y => '<td>' + y + '</td>').join(''));

  return ret.map((first, id) => '<th>' + labels[id] + '</th>' + first).join('\n');

  // ret = '[[' + ret + ']]';
  // return ret;
};

export function getRowColumn(result: TaskResultType, metric: string) {
  if (metric === 'ps') {
    return result.debug.split('/').map(x => getRoundedScore(parseFloat(x))).join('/');
  }

  if (typeof (result.score) === 'undefined') {
    return '-';
  }

  if (result.score.toString() === '-') {
    return '-';
  }

  let actualScore = getRoundedScore(result.score);

  if (result.score === 0) {
    actualScore = '0.0';
  }

  const avgMetrics = ["f1", "avgf1", "multirc", "record", "winogender"];
  if (avgMetrics.indexOf(metric.toLowerCase()) !== -1) {
    const accuracy = getRoundedScore(result.accuracy);
    return actualScore.toString() + '/' + accuracy.toString();
  } else {
    return actualScore;
  }
}
