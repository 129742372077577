import { connect } from 'react-redux';
import { StoreState } from '../types/index';
import Leaderboard from '../components/Leaderboard';
import { fetchTasks, fetchResults, updateSelectedUser, fetchMetadata } from '../actions';

const mapStateToProps = (state: StoreState) => ({
  userInfo: state.userInfo,
  resultList: state.resultList,
  taskList: state.taskList,
  general: state.general,
  metadata: state.metadata
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchTasks: () => dispatch(fetchTasks()),
  fetchResults: (metadata: StoreState['metadata']) => dispatch(fetchResults(metadata)),
  fetchMetadata: () => dispatch(fetchMetadata()),
  updateSelectedUser: (index: number) => dispatch(updateSelectedUser(index))
});

const LeaderboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Leaderboard);

export default LeaderboardContainer;
