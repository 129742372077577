import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { StoreState } from '../types/index';
import {
  TasksAction, fetchTasks,
  FetchSubmissionAction, fetchSubmission
} from '../actions/index';
import Submission from '../components/Submission';

const mapStateToProps = (state: StoreState) => ({
  userInfo: state.userInfo,
  taskList: state.taskList,
  general: state.general
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchTaskList: () => dispatch(fetchTasks()),
  fetchSubmission: (submissionId: string) => dispatch(fetchSubmission(submissionId))
});

const SubmissionContainer = connect(
  mapStateToProps,
  mapDispatchToProps)(Submission);

export default SubmissionContainer;
