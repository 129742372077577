import * as React from 'react';
import AddTask from './AddTask';
import * as firebase from 'firebase';
import { db, storage } from '../firebase';
import { SubmissionError } from 'redux-form';
import { StoreState } from '../types';

interface Props {
  userInfo: StoreState['userInfo'];
}

// TODO: Make sure identifier is unique
// TODO: Extract this out into separate file.
// TODO: Replace strings with string constants
class AddTaskForm extends React.Component<Props, {}> {
  submit = (values: any) => {
    if (!this.props.userInfo.isAdmin) {
      throw new SubmissionError({ _error: 'You need to be admin' });
    }
    const valuesNeeded = ['identifier', 'name', 'metric', 'type', 'testJSONL',
      'infoURL', 'superglueVersion', 'downloadURL'];

    // Check for all fields
    valuesNeeded.forEach((item) => {
      if (!values[item]) {
        throw new SubmissionError({ _error: 'Missing ' + item });
      }
    });

    values.weight = parseFloat(values.weight);
    values.superglueVersion = parseFloat(values.superglueVersion);
    const tasksAdminInfoRef = db.ref('superglue/tasksAdminInfo');
    const taskId = tasksAdminInfoRef.push().key as string;

    // First upload the jsonl
    return this.uploadTaskJSONL(values, taskId).then(
      () => {
        // Then add jsonl to DB
        return this.addTaskJSONLToDB(tasksAdminInfoRef, taskId).then(
          // Finally add task to DB
          () => this.addTaskToDB(values, taskId).then(
            () => console.log('Uploaded'),
            (error: string) => this.throwError('Add to DB failed', error)
          ),
          (error: string) => this.throwError('Add to DB failed', error)
        );
      },
      (error: string) => this.throwError('Upload failed', error)
    );
  }

  addTaskJSONLToDB = (ref: firebase.database.Reference, taskId: string) => {
    return ref.child(taskId).set({
      jsonlPath: `superglue/tasksJSONL/${taskId}.jsonl`
    });
  }

  addTaskToDB = (values: any, taskId: string) => {
    delete values.testJSONL;
    console.log(values);
    const tasksRef = db.ref('superglue/tasks');
    values.jsonlWithoutLabels = 'https://not_uploaded_yet';
    return tasksRef.child(taskId).set(values);
  }

  throwError = (errorMsg: string = 'Failed', error: any) => {
    throw new SubmissionError({ message: errorMsg, _error: error });
  }

  isValidSubmission = () => {
    return this.props.userInfo.isAuthenticated && this.props.userInfo.isAdmin;
  }

  uploadTaskJSONL = (values: any, taskId: string) => {
    return new Promise((resolve, reject) => {
      if (!this.isValidSubmission()) {
        reject('You need to be an admin to upload');
      }

      const tasksJSONLFolderRef = storage.ref().child('superglue').child('tasksJSONL');
      const taskJSONLName = taskId + '.jsonl';

      const jsonlRef = tasksJSONLFolderRef.child(taskJSONLName);

      const metadata = {
        customMetadata: {
          uploaderUid: this.props.userInfo.uid,
          originalName: values.testJSONL.name,
          uploaderEmail: this.props.userInfo.email
        }
      } as firebase.storage.SettableMetadata;

      jsonlRef.put(values.testJSONL, metadata).then(
        (snapshot: firebase.storage.UploadTaskSnapshot) => {
          resolve(snapshot);
        },
        () => {
          reject('JSONL upload failed');
        }
      );
    });
  }

  render() {
    return (
      <AddTask
        initialValues={{ weight: 1 }}
        onSubmit={this.submit}
      />
    );
  }
}

export default AddTaskForm;
