import { auth, db } from '../firebase';
import * as firebase from 'firebase';

export function createUserIfNotExists() {
  let user = auth.currentUser;
  if (!user) {
    return Promise.resolve([]);
  }

  let usersRef = db.ref('users');
  let userData = {
    privateFields: {
      email: user.email,
      createdOn: firebase.database.ServerValue.TIMESTAMP,
      googleId: user.uid
    },
    publicFields: {
      avatarImageUrl:
        user.photoURL ||
        'https://upload.wikimedia.org/wikipedia/commons/thumb/1/12/User_icon_2.svg/220px-User_icon_2.svg.png',
      displayName: user.displayName || user.email
    }
  };

  const uidRef = usersRef.child(user.uid);
  return uidRef.once('value').then((currentUserData: firebase.database.DataSnapshot) => {
    if (
      currentUserData.val() === null ||
      !currentUserData.val().privateFields.email
    ) {
      const promises: Promise<any>[] = [];
      promises.push(uidRef.child('privateFields').set(userData.privateFields));
      promises.push(uidRef.child('publicFields').set(userData.publicFields));

      return Promise.all(promises);
    } else {
      return Promise.resolve([]);
    }
  });
}
