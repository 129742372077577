import * as React from 'react';
import * as firebase from 'firebase';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { StoreState, ResultType } from '../types';
import { SubmissionError } from 'redux-form';

import { db } from '../firebase';
import { compose } from 'redux';
import { SubmitComponent } from './Submit';


interface SubmissionEditProps {
  userInfo: StoreState['userInfo'];
}

let SubmissionEditForm: React.StatelessComponent<SubmissionEditProps & InjectedFormProps<{}, SubmissionEditProps>> = (props) => {
  return  (
    <SubmitComponent
      userInfo={props.userInfo}
      isEditForm={true}
      handleSubmit={props.handleSubmit}
      submitSucceeded={props.submitSucceeded}
      submitting={props.submitting}
    />
  );
};

const SubmissionEditFormComponent = compose(reduxForm<{}, SubmissionEditProps>({
  form: 'submissionEditForm',
}))(SubmissionEditForm);

interface Props {
  userInfo: StoreState['userInfo'];
  submission: ResultType;
  submissionId: string;
}

class SubmissionEdit extends React.Component<Props, {}> {
  submit = (values: any) => {

    const valuesNeeded = ['name', 'url', 'modelDescription',
      'parameterDescription', 'public'];

    // Check for all fields
    valuesNeeded.forEach((item) => {
      if (typeof (values[item]) === 'undefined') {
        throw new SubmissionError({ _error: 'Missing ' + item });
      }
    });

    if (typeof (values.woFineTuningParameters) === 'undefined') {
      values.woFineTuningParameters = -1;
    }
    if (typeof (values.fineTunedParameters) === 'undefined') {
      values.fineTunedParameters = -1;
    }
    if (typeof (values.taskSpecificParameters) === 'undefined') {
      values.taskSpecificParameters = -1;
    }

    const submissionId = this.props.submissionId;
    const isPublic = values.public;
    const resultProfile = {
      name: values.name,
      modelDescription: values.modelDescription,
      url: values.url,
      parameterDescription: values.parameterDescription,
      public: !!values.public,
      woFineTuningParameters: parseInt(values.woFineTuningParameters, 10),
      fineTunedParameters: parseInt(values.fineTunedParameters, 10),
      taskSpecificParameters: parseInt(values.taskSpecificParameters, 10),
      adminApproved: values.adminApproved || false
    };

    const resultVisibilityRef: firebase.database.Reference = db.ref().child(
      `superglue/resultsVisibility/${submissionId}`);
    const resultRef: firebase.database.Reference = db.ref().child(`superglue/results/${submissionId}/editable`);

    return Promise.all([
      resultVisibilityRef.set({
        public: isPublic,
        adminApproved: values.adminApproved || false
      }), resultRef.set(resultProfile)]).then(
        () => console.log('Upload successful'),
        (e) => this.throwError('Upload failed' + e)
      );

  }

  throwError = (err: string) => {
    throw new SubmissionError({ _error: err });
  }

  render() {
    const submission = this.props.submission;
    const initialValues = { ...submission.editable };
    initialValues.public = !!initialValues.public;

    return <SubmissionEditFormComponent
      userInfo={this.props.userInfo}
      initialValues={initialValues}
      onSubmit={this.submit}
    />;
  }
}

export default SubmissionEdit;
