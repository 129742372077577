import * as React from 'react';
import { StoreState, ResultType, TaskResultType } from '../types';
import { TableRow, TableCell, Table, TableHead, TableBody, Tab, Tabs } from '@material-ui/core';
import { randomString, getMetricFullName, getRoundedScore, getRowColumn } from '../utils/general';
import { boldStyle, tableStyle, alignCenterStyle, overflowHidden } from '../styles';
import SubmissionEditContainer from '../containers/SubmissionEditContainer';
const loading = require('../loading.svg');

interface Props {
  userInfo: StoreState['userInfo'];
  taskList: StoreState['taskList'];
  general: StoreState['general'];
  fetchSubmission: (submissionId: string) => Promise<void>;
  fetchTaskList: () => Promise<void>;
  match?: {
    params: {
      uid: string;
      submissionId: string;
    }
  };
}

class Submission extends React.Component<Props, {}> {
  state = {
    selectedTab: 0
  };

  changeTab = (event: React.ChangeEvent<{}>, index: number) => {
    this.setState({ selectedTab: index });
  }

  componentDidMount() {
    const submissionId = this.props.match!.params.submissionId;

    this.props.fetchSubmission(submissionId);

    if (Object.keys(this.props.taskList.tasks).length === 0) {
      this.props.fetchTaskList();
    }
  }

  getThStyle = () => {
    return {
      ...boldStyle,
      // backgroundColor: STRING_CONSTANTS.THEME[this.props.general.theme].BACKGROUND_COLOR
    };
  }

  getTasks = (taskType: string, mainOnly = false) => {
    return Object.keys(this.props.taskList.tasks)
      .filter(key =>
        this.props.taskList.tasks[key].type === taskType
        && (mainOnly ? this.props.taskList.tasks[key].auxiliaryType === 'main' : true)
      )
      .map((key) => {
        const task = this.props.taskList.tasks[key];
        return [key, task.name, task.identifier, task.metric];
      });
  }

  getRowColumn = (result: TaskResultType, metric: string) => {
    return getRowColumn(result, metric);
  }

  getRows = (submission: ResultType, taskType: string, mainOnly = false) => {
    const tasks = this.getTasks(taskType, mainOnly);

    const rows = tasks.map((item, index) => {
      const identifier = this.props.taskList.tasks[item[0]].identifier;
      let score = this.getRowColumn(submission.tasks[item[0]], item[3]);

      if (identifier === 'QNLI' && parseInt(submission.createdOn, 10) < 1549027413000) {
        score = '-';
      }

      return (
        <TableRow key={randomString()}>
          <TableCell colSpan={2} style={alignCenterStyle}>{item[1]}</TableCell>
          <TableCell style={alignCenterStyle}>{item[2]}</TableCell>
          <TableCell style={alignCenterStyle}>{getMetricFullName(item[3])}</TableCell>
          <TableCell style={alignCenterStyle}>
            {score}
          </TableCell>
        </TableRow>
      );
    });

    return rows;
  }

  displayTable = (items: any[], tabIndex: number) => {
    return (
      this.state.selectedTab === tabIndex ?
        (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2} style={this.getThStyle()}>Task</TableCell>
                <TableCell style={this.getThStyle()}>Task</TableCell>
                <TableCell style={this.getThStyle()}>Metric</TableCell>
                <TableCell style={this.getThStyle()}>Score</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items}
            </TableBody>
          </Table>
        ) : ''
    );
  }

  a11yProps = (index: any) => {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  }

  displaySubmission = (submission: ResultType) => {
    let primaryRows = this.getRows(submission, 'primary');
    const mainAuxiliaryRows = this.getRows(submission, 'auxiliary', true);
    primaryRows = primaryRows.concat(mainAuxiliaryRows);
    const allAuxiliaryRows = this.getRows(submission, 'auxiliary');

    return (
      <div className="submission-container">
        {
          this.props.userInfo.uid === this.props.match!.params.uid || this.props.userInfo.isAdmin ?
            <SubmissionEditContainer
              submission={submission}
              submissionId={this.props.match!.params.submissionId}
            /> : ''
        }
        <h2>Results for submission <strong>{submission.editable.name}</strong></h2>
        <h3>Score: <strong>{getRoundedScore(submission.macroScore)}</strong></h3>
        <Tabs centered={true} value={this.state.selectedTab} onChange={this.changeTab}>
          <Tab value={0} label="Primary" {...this.a11yProps(0)} />
          <Tab value={1} label="Broadcoverage" {...this.a11yProps(1)} />
        </Tabs>
        {this.displayTable(primaryRows, 0)}
        {this.displayTable(allAuxiliaryRows, 1)}
        <br />
        <br />
      </div>
    );

  }

  render() {
    const submissionId = this.props.match!.params.submissionId;

    let isThere = false;

    if (this.props.userInfo.otherSubmissions[submissionId]) {
      isThere = true;
    }
    return (
      (this.props.userInfo.isFetchingSubmissions || this.props.taskList.isRequested) ? (
        <div style={overflowHidden}>
          <p>Checking for submission...</p>
          <img src={loading} className="App-logo" alt="Loading" />
        </div>
      ) : (isThere ?
        this.displaySubmission(this.props.userInfo.otherSubmissions[submissionId]) :
        (<div>No submission found</div>))
    );
  }
}

export default Submission;
