import {
  AuthChangedAction, LoginAction, LogoutAction, TasksAction,
  CanUploadCheckAction, ResultsAction, UpdateSelectedUserAction,
  StartFetchUserSubmission, FinishFetchUserSubmission,
  StartFetchSubmission, FinishFetchSubmission, MetadataAction
} from '../actions';
import { StoreState } from '../types/index';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import * as constants from '../constants/index';
import {
  userInfoDefault, taskListDefault, submissionDefault,
  resultListDefault, generalDefault, metadataDefault
} from '../defaults/index';
import { reduceReducers } from '../utils/general';
import { auth } from '../firebase';

type UserType = StoreState['userInfo'];
type TaskList = StoreState['taskList'];
type SubmissionInfo = StoreState['submission'];
type ResultList = StoreState['resultList'];
type GeneralType = StoreState['general'];

function authChangedReducer(state: UserType = userInfoDefault, action: AuthChangedAction): UserType {
  if (action.type === constants.USER_HAS_LOGGED_IN) {
    if (auth.currentUser) {
      const newState = {
        ...state,
        isAuthenticated: true,
        uid: auth.currentUser.uid,
        avatarImageUrl: action.data.user.publicFields.avatarImageUrl,
        displayName: action.data.user.publicFields.displayName,
        isAdmin: !!action.data.user.isAdmin,
        isBanned: !!action.data.user.isBanned,
        email: action.data.user.privateFields.email,
        redirectToReferrer: true,
        isCheckingLogin: false
      };
      return newState;
    } else {
      return state;
    }
  } else if (action.type === constants.USER_HAS_LOGGED_OUT) {
    const newState = { ...state, isAuthenticated: false, uid: null, isCheckingLogin: false };
    return newState;
  } else {
    return state;
  }
}

function loginUserWithGoogleReducer(state: UserType = userInfoDefault, action: LoginAction): UserType {
  if (action.type === constants.LOGIN_WITH_GOOGLE) {
    if (action.data.result || auth.currentUser) {
      const user = action.data.result || auth.currentUser;

      if (!user) {
        return state;
      }

      return {
        ...state,
        isAuthenticated: true,
        uid: user.uid,
        redirectToReferrer: true,
        isCheckingLogin: false
      };
    } else {
      return state;
    }
  } else {
    return state;
  }
}

function logoutUser(state: UserType = userInfoDefault, action: LogoutAction): UserType {
  if (action.type === constants.LOGOUT_USER) {
    if (!auth.currentUser) {
      return userInfoDefault;
    } else {
      return state;
    }
  } else {
    return state;
  }
}

function startFetchUserSubmission(state: UserType = userInfoDefault, action: StartFetchUserSubmission): UserType {
  if (action.type === constants.START_FETCH_USER_SUBMISSION) {
    return {
      ...state,
      isFetchingSubmissions: true
    };
  } else {
    return state;
  }
}

function finishFetchUserSubmission(state: UserType = userInfoDefault, action: FinishFetchUserSubmission): UserType {
  if (action.type === constants.FINISH_FETCH_USER_SUBMISSION) {
    return {
      ...state,
      isFetchingSubmissions: false,
      submissions: action.data
    };
  } else {
    return state;
  }
}

function startFetchSubmission(state: UserType = userInfoDefault, action: StartFetchSubmission): UserType {
  if (action.type === constants.START_FETCH_SUBMISSION) {
    return {
      ...state,
      isFetchingSubmissions: true
    };
  } else {
    return state;
  }
}

function finishFetchSubmission(state: UserType = userInfoDefault, action: FinishFetchSubmission): UserType {
  if (action.type === constants.FINISH_FETCH_SUBMISSION) {
    const newState = {
      ...state,
      isFetchingSubmissions: false,
    };

    newState.otherSubmissions[action.data.id] = action.data.data;
    return newState;
  } else {
    return state;
  }
}

function requestTasks(state: TaskList = taskListDefault, action: TasksAction) {
  if (action.type === constants.TASKS_REQUESTED) {
    return {
      ...state,
      isRequested: true
    };
  } else {
    return state;
  }
}

function receiveTasks(state: TaskList = taskListDefault, action: TasksAction) {
  if (action.type === constants.TASKS_FETCHED) {
    return {
      ...state,
      isRequested: false,
      tasks: action.data.result
    };
  } else {
    return state;
  }
}

function requestResults(state: ResultList = resultListDefault, action: ResultsAction) {
  if (action.type === constants.RESULTS_REQUESTED) {
    return {
      ...state,
      isRequested: true
    };
  } else {
    return state;
  }
}

function receiveResults(state: ResultList = resultListDefault, action: ResultsAction) {
  if (action.type === constants.RESULTS_FETCHED) {
    return {
      ...state,
      isRequested: false,
      results: action.data.result
    };
  }
  return state;
}

function requestMetadata(state: StoreState['metadata'] = metadataDefault, action: MetadataAction) {
  if (action.type === constants.REQUEST_METADATA) {
    return {
      ...state,
      isRequested: true
    };
  } else {
    return state;
  }
}

function receiveMetadata(state: StoreState['metadata'] = metadataDefault, action: MetadataAction) {
  if (action.type === constants.RECEIVE_METADATA) {
    let version = action.data.result.version;

    if (typeof(version) === 'string') {
      version = parseFloat(version).toFixed(1);
    }
    return {
      ...state,
      version: version,
      isRequested: false
    };
  } else {
    return state;
  }
}

function updateSelectedUserReducer(state: ResultList = resultListDefault,
  action: UpdateSelectedUserAction): ResultList {
  if (action.type === constants.UPDATE_SELECTED_USER) {
    return {
      ...state,
      selectedUserOpen: !state.selectedUserOpen,
      selectedUser: action.data
    };
  }

  return state;
}
function startCanUploadCheckReducer(state: SubmissionInfo = submissionDefault,
  action: CanUploadCheckAction) {
  if (action.type === constants.START_CAN_UPLOAD_CHECK) {
    return {
      ...state,
      isChecking: true
    };
  } else {
    return state;
  }

}

function finishCanUploadCheckReducer(state: SubmissionInfo = submissionDefault,
  action: CanUploadCheckAction) {
  if (action.type === constants.FINISH_CAN_UPLOAD_CHECK) {
    return {
      isChecking: false,
      canUpload: action.data
    };
  } else {
    return state;
  }
}



function generalThemeReducer(state: GeneralType = generalDefault, action: any) {
  if (action.type === constants.CHANGE_THEME) {
    return {
      theme: state.theme === 'LIGHT' ? 'DARK' : 'LIGHT'
    };
  } else {
    return state;
  }
}

const userInfoReducers = reduceReducers(
  authChangedReducer,
  loginUserWithGoogleReducer,
  logoutUser,
  startFetchUserSubmission,
  finishFetchUserSubmission,
  startFetchSubmission,
  finishFetchSubmission
);

const taskListReducers = reduceReducers(
  requestTasks,
  receiveTasks
);

const submissionReducers = reduceReducers(
  startCanUploadCheckReducer,
  finishCanUploadCheckReducer
);

const resultListReducers = reduceReducers(
  requestResults,
  receiveResults,
  updateSelectedUserReducer
);

const generalReducers = reduceReducers(
  generalThemeReducer
);

const metadataReducers = reduceReducers(
  requestMetadata,
  receiveMetadata
);

export const defaultState = combineReducers<StoreState>({
  userInfo: userInfoReducers,
  taskList: taskListReducers,
  submission: submissionReducers,
  resultList: resultListReducers,
  general: generalReducers,
  form: formReducer,
  metadata: metadataReducers
});
