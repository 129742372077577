import { createMuiTheme } from '@material-ui/core/styles';


export const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#bd492a',
      main: '#bd492a',
      dark: '#bd492a',
      contrastText: '#fff',
    },
    secondary: {
      light: '#64a2c8',
      main: '#64a2c8',
      dark: '#64a2c8',
      contrastText: '#000',
    }
  },
  typography: {
    useNextVariants: true,
  },
});

export const introStyles = (theme: any) => ({
  logo: {
    width: '100%',
    '&:hover': {
      textDecoration: 'none',
      border: 'none'
    }
  },
  firstLogo: {
    marginLeft: '10%',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0
    }
  },
  superglueLogo: {
    width: '70%',
    height: '3.5em'
  },
  logoContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    paddingTop: '2em'
  },
  logoImage: {
    width: '100%'
  }
});

export const headerStyles = (theme: any) => ({
  fontIcon: {
    marginRight: '10px',
    cursor: 'pointer' as 'pointer',
    textAlign: 'center' as 'center',
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.down('md')]: {
      color: theme.palette.primary.main
    },
  },
  superglueIcon: {
    height: '1.15em',
    verticalAlign: 'sub',
    [theme.breakpoints.down('md')]: {
      height: '1em'
    }
  },
  glueIcon: {
    height: '1.15em',
    verticalAlign: 'sub',
    paddingRight: '0.2em',
    [theme.breakpoints.down('md')]: {
      height: '1em'
    }

  },
  link: {
    display: 'flex',
    alignItems: 'center' as 'center',
    justifyContent: 'space-between' as 'space-between',
    marginRight: '10px',
    color: theme.palette.primary.contrastText,
    fontSize: '0.8em',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  grow: {
    flexGrow: 1,
    justifyContent: 'flex-start'
  },
  buttonLink: {
    color: theme['palette']['primary']['contrastText'],
    textDecoration: 'none',
    marginRight: '0.9em',
    '&:first-child': {
      borderBottom: '2px dotted ' + theme['palette']['primary']['contrastText']
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '0.8em',
      marginRight: '0.4em',
    }
  },
});

export const drawerStyles = (theme: any) => ({
  paper: {
    width: '15%',
    [theme.breakpoints.down('lg')]: {
      width: '22%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '80%'
    },
    [theme.breakpoints.down('md')]: {
      width: '50%'
    }
  },
});

export const submitFormStyles = (themeObject: any) => ({
  form: {
    width: '40%',
    margin: '0 auto',
    textAlign: 'center' as 'center',
    [themeObject.breakpoints.down('md')]: {
      width: '90%'
    }
  }
});

export const leaderboardStyles = (theme: any) => ({
  diagnosticsListItem: {
    display: 'block'
  }
});

export const alignCenterStyle = {
  textAlign: 'center' as 'center',
  fontSize: '1em'
};

export const boldStyle = {
  textAlign: 'center' as 'center',
  fontWeight: 'bold' as 'bold',
  // fontSize: '1em',
};

export const tabStyle = {
  ...boldStyle,
  border: '1px solid #494647'
};

export const tableStyle = {
  borderRadius: '6px'
};

export const fontIconStyle = {
  marginRight: '10px',
  cursor: 'pointer' as 'pointer'
};

export const fontIconUrlStyle = {
  cursor: 'pointer' as 'pointer'
};

export const plusColumn = {
  width: '0.2em',
  paddingLeft: '15px'
};

export const linkStyle = {
  display: 'flex' as 'flex',
  alignItems: 'center' as 'center',
  justifyContent: 'center' as 'center',
};

export const rowColumnStyle = {
  textAlign: 'center' as 'center',
  textOverflow: 'inherit' as 'inherit',
  // fontSize: '0.7em',
  // paddingLeft: '0.2em',
  // paddingRight: '0.2em',
  // paddingTop: '0.2em',
  // paddingBottom: '0.2em',
};

export const submitInfoListItemStyle = {
  whiteSpace: 'pre' as 'pre',
  lineHeight: '1.2'
};

export const textRowColumnStyle = {
  ...rowColumnStyle,
  textAlign: 'left' as 'left'
};

export const numberRowColumnStyle = {
  ...rowColumnStyle,
  textAlign: 'right' as 'right'
};

export const multiLineLabelStyle = {
  width: '100%',
  left: '0px',
  textAlign: 'left' as 'left'
};

export const overflowHidden = {
  overflow: 'hidden' as 'hidden'
};

export const aboutStyles = (themeObject: any) => ({
  listItem: {
    cursor: 'pointer'
  }
});