import * as firebase from 'firebase';
const config = require('./config.json');
import * as constants from './constants';
import 'firebase/storage';

export const firebaseApp = firebase.initializeApp(config);
export const db = firebaseApp.database();
export const auth = firebaseApp.auth();
export const storage = firebaseApp.storage();

export const storageKey = constants.STRING_CONSTANTS.AUTHENTICATION_LOCAL_STORAGE_KEY;

export const googleProvider = new firebase.auth.GoogleAuthProvider();

export const isAuthenticated = () => {
  return !!auth.currentUser || !!localStorage.getItem(storageKey);
};
