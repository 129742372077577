import * as firebase from 'firebase';
import { db } from '../firebase';
import { StoreState } from '../types/index';

type UserSubmissionsType = StoreState['userInfo']['submissions'];
export function getUserSubmissions(userId: string): Promise<any> {
  return db.ref().child(`users/${userId}/publicButAddable/superglue/submissions`)
    .once('value')
    .then((snapshot: firebase.database.DataSnapshot) => {
      const submissions = snapshot.val();
      const promises: Promise<any>[] = [];

      if (!submissions) {
        return [];
      }
      Object.keys(submissions).forEach((submissionId) => {
        const dbPromise = db.ref().child(`superglue/results/${submissionId}`).once('value');
        const promise = new Promise((resolve, reject) => {
          dbPromise.then(
            (value) => resolve({ value, submissionId }),
            (error) => reject(error)
          );
        });
        promises.push(promise);
      });

      return Promise.all(promises);
    })
    .then(values => values.filter(value => !(value instanceof Error)))
    .then(values => {
      const submissions: UserSubmissionsType = {};
      values.forEach((value, key) => {
        const snapshotVal = value.value.val();

        if (!snapshotVal) {
          return;
        }
        submissions[value.submissionId] = snapshotVal;
      });

      return submissions;
    });
}
