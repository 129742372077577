import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { randomString } from '../utils/general';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { aboutStyles } from '../styles';
import { withStyles } from '@material-ui/core';

const faqs = require('../faqs.json').faqs;

interface Props {
  classes: any;
}

class About extends React.Component<Props, {}> {
  state = {
    status: Array.apply(null, Array(faqs.length)).map(() => 0)
  };

  toggle = (index: number) => {
    const newStatus: number[] = this.state.status.slice();
    newStatus[index] = Number(!newStatus[index]);

    this.setState({
      status: newStatus
    });
  }

  getStyles = (index: number) => {
    if (this.state.status[index]) {
      return {};
    } else {
      return { display: 'none' };
    }
  }

  render() {
    return (
      <div className="faq">
        <Paper
          elevation={0}
          style={{
            backgroundColor: '#f7f7f7'
          }}
        >
          <List>
            <ListSubheader disableSticky={true}>Frequently asked questions</ListSubheader>
            {
              faqs.length > 0 ? (
                faqs.map((faq: any, index: number) => {
                  return (
                    <List>
                      <ListItem
                        className={this.props.classes.listItem}
                        onClick={() => this.toggle(index)}
                      >
                        <ListItemText primary={(index + 1) + '. ' + faq.question} />
                        {this.state.status[index] === 1 ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={this.state.status[index] === 1} key={index}>
                        <List>
                          <ListItem
                            style={{ lineHeight: 1.4 }}
                            key={randomString()}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: (Array.isArray(faq.answer) ?
                                  faq.answer.join(' ') : faq.answer)
                              }}
                            />
                          </ListItem>
                        </List>
                      </Collapse>
                    </List>
                  );
                })
              ) : ''
            }
          </List>
        </Paper>
      </div>
    );
  }
}

export default withStyles(aboutStyles)(About);
