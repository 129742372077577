import * as React from 'react';
import { StoreState, TaskResultType, UserResultType, ResultType } from '../types';
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  withStyles,
  ListItemText,
  Typography
} from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import Icon from '@material-ui/core/Icon';
import Drawer from '@material-ui/core/Drawer';
import ReactTooltip from 'react-tooltip';
import {
  randomString, timeSince, getPrintableConfusion,
  getRoundedScore, getMetricFullName, getRowColumn
} from '../utils/general';
import {
  boldStyle, tableStyle, rowColumnStyle, textRowColumnStyle, numberRowColumnStyle,
  fontIconStyle, plusColumn, linkStyle, overflowHidden, fontIconUrlStyle, leaderboardStyles, drawerStyles
} from '../styles';
import NotReady from './NotReady';
import { Link } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
const loading = require('../loading.svg');

interface Props {
  userInfo: StoreState['userInfo'];
  taskList: StoreState['taskList'];
  resultList: StoreState['resultList'];
  general: StoreState['general'];
  metadata: StoreState['metadata'];
  fetchResults: (metadata: StoreState['metadata']) => void;
  fetchTasks: () => void;
  fetchMetadata: () => void;
  updateSelectedUser: (index: number) => void;
  classes: any;
}

type StateType = {
  drawerOpen: boolean;
  submission: ResultType | null;
};

class Leaderboard extends React.Component<Props, {}> {
  state: StateType = {
    drawerOpen: false,
    submission: null
  };

  submissions: ResultType[] = [];

  constructor(props: Props) {
    super(props);
    this.submissions = [];
  }

  componentDidMount() {
    // First fetch tasks
    if (!this.props.taskList.tasks || Object.keys(this.props.taskList.tasks).length === 0) {
      this.props.fetchTasks();
    }
    if (this.props.metadata.version === null) {
      this.props.fetchMetadata();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.metadata.version != prevProps.metadata.version) {
      // This will not do anything unless tasks and metadata has been fetched
      this.props.fetchResults(this.props.metadata);
    }
  }

  handleCollapseClick = (e: React.MouseEvent<HTMLDivElement>, row: number) => {
    this.props.updateSelectedUser(row);
    e.stopPropagation();
  }

  handleCellClick = (row: number, col: number) => {
    this.changeDrawerSubmission(this.submissions[row]);
  }

  getThStyle = () => {
    return {
      ...boldStyle,
      ...rowColumnStyle,
      // backgroundColor: STRING_CONSTANTS.THEME[this.props.general.theme].BACKGROUND_COLOR
    };
  }

  changeDrawerSubmission = (submission: ResultType) => {
    this.setState({
      drawerOpen: true,
      submission: submission
    });
  }

  toggleDrawer = (open: boolean) => {
    this.setState({
      drawerOpen: open
    })
  }

  getSortValueForIdentifier = (a: string, b: string) => {
    if (a === 'AX-b' || a === 'AX-g') {
      return 1;
    }

    if (b === 'AX-b' || b === 'AX-g') {
      return -1;
    }

    return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
  }

  getHeader = (taskType: string, mainAuxiliary: boolean = true) => {
    const tasks = this.props.taskList.tasks;
    const thStyle = this.getThStyle();
    const textThStyle = { ...thStyle, textAlign: 'left' as 'left' };
    const numberThStyle = { ...thStyle, textAlign: 'right' as 'right' };
    return (
      <TableHead>
        <TableRow>
          <TableCell style={{ ...textThStyle, ...plusColumn }} />
          <TableCell style={numberThStyle}>Rank</TableCell>
          <TableCell style={textThStyle}>Name</TableCell>
          <TableCell style={textThStyle}>Model</TableCell>
          <TableCell style={thStyle}>URL</TableCell>
          <TableCell className="task-score-column" style={numberThStyle}>Score</TableCell>
          {
            Object.keys(tasks).filter((key) => {
              // First filter based on the selected task type
              const task = this.props.taskList.tasks[key];

              // If type is missing, it should be primary
              if (!task.type && taskType === 'primary') {
                return true;
              } else if (taskType === 'primary' && mainAuxiliary === true
                && task.type === 'auxiliary' && task.auxiliaryType === 'main') {
                return true;
              } else if (task.type === taskType) {
                if (task.type === 'auxiliary') {
                  if (task.auxiliaryType === 'subcategory') {
                    return false;
                  } else {
                    return true;
                  }
                }
                return true;
              } else {
                return false;
              }
            }).sort((a, b) => {
              return this.getSortValueForIdentifier(tasks[a].identifier, tasks[b].identifier);
            }).map((column: string, key: number) => {
              // Generate header using original task list to handle
              // missing tasks in submissions properly, if it happens
              return (
                <TableCell
                  className="task-score-column"
                  data-tip={tasks[column].name + '-' + getMetricFullName(tasks[column].metric)}
                  style={numberThStyle}
                  key={randomString()}
                >
                  {tasks[column].identifier}
                </TableCell>
              );
            })
          }
        </TableRow>
      </TableHead>
    );
  }

  getRowColumn = (result: TaskResultType, metric: string) => {
    return getRowColumn(result, metric);
  }

  shouldDisplayStyle = (rank: number, maxScoreSubmission: boolean = false, isBaselineUser: boolean = true) => {
    return (maxScoreSubmission || isBaselineUser ||
      (this.props.resultList.selectedUser === rank &&
        this.props.resultList.selectedUserOpen))
      ? {} : {
        display: 'none'
      };
  }

  fixDisplayName = (name: string) => {
    let finalName = name === 'GLUE Baselines' ? 'SuperGLUE Baselines' : name;
    if (finalName === "GLUE Human Baselines") {
      finalName = "Super" + finalName;
    }

    return finalName;
  }

  getRow = (taskType: string, result: UserResultType, submission: ResultType,
    maxScoreSubmission: boolean, rank: number, mainAuxiliary: boolean = true) => {
    this.submissions.push(submission);
    return (
      <TableRow
        style={this.shouldDisplayStyle(rank, maxScoreSubmission, result.displayName === 'GLUE Baselines')}
        key={randomString()}
        onClick={() => this.changeDrawerSubmission(submission)}
      >
        <TableCell style={{ ...rowColumnStyle, ...plusColumn }}>
          {
            (maxScoreSubmission && result.displayName !== 'GLUE Baselines' && result.otherSubmissions.length > 0) ?
              this.props.resultList.selectedUserOpen &&
                this.props.resultList.selectedUser === rank ?
                <div style={linkStyle} onClick={(e) => this.handleCollapseClick(e, rank)}>
                  <Icon className="fa fa-minus" style={fontIconStyle} />
                </div> :
                <div style={linkStyle} onClick={(e) => this.handleCollapseClick(e, rank)}>
                  <Icon className="fa fa-plus" style={fontIconStyle} />
                </div>
              : ''
          }
        </TableCell>
        <TableCell style={rowColumnStyle}>
          {maxScoreSubmission ? (result.maxScoreSubmission.macroScore >= -100 ? (rank + 1) : '-') : ''}
        </TableCell>
        <TableCell style={textRowColumnStyle}>
          <ReactTooltip effect="solid" place="right" />
          {
            maxScoreSubmission ?
              this.fixDisplayName(result.displayName)
              : ''
          }
        </TableCell>
        <TableCell
          data-tip={submission.editable ? submission.editable.name : ''}
          style={textRowColumnStyle}

        >
          <ReactTooltip effect="solid" place="right" />
          {submission.editable ? submission.editable.name : ''}
        </TableCell>
        <TableCell style={rowColumnStyle}>
          {submission.editable ? ((submission.editable.url && submission.editable.url.length) ?
            <a href={submission.editable.url}>
              <Icon className="fa fa-external-link" style={fontIconUrlStyle} />
            </a> : '') : ''}
        </TableCell>
        <TableCell className="task-score-column" style={numberRowColumnStyle}>
          {getRoundedScore(submission.macroScore)}
        </TableCell>
        {
          Object.keys(this.props.taskList.tasks).filter((key) => {
            // First filter based on whether this is selected tab task or not
            const task = this.props.taskList.tasks[key];

            // If type is missing, it should be primary
            if (!task.type && taskType === 'primary') {
              return true;
            } else if (taskType === 'primary' && mainAuxiliary === true
              && task.type === 'auxiliary' && task.auxiliaryType === 'main') {
              return true;
            } else if (task.type === taskType) {
              if (task.type === 'auxiliary') {
                if (task.auxiliaryType === 'subcategory') {
                  return false;
                } else {
                  return true;
                }
              }
              return true;
            } else {
              return false;
            }
          }).sort((a, b) => {
            return this.getSortValueForIdentifier(this.props.taskList.tasks[a].identifier, this.props.taskList.tasks[b].identifier);
          }).map((column: string, key: number) => {
            // Then, get the column as required
            const task = submission.tasks[column] || {};
            const metric = this.props.taskList.tasks[column].metric;
            let score = this.getRowColumn(task, metric);
            const identifier = this.props.taskList.tasks[column].identifier;

            if (identifier === 'QNLI' && parseInt(submission.createdOn, 10) < 1549027413000) {
              score = '-';
            }

            return (
              <TableCell
                // tooltip={}
                className="task-score-column"
                key={randomString()}
                style={numberRowColumnStyle}
                data-tip={score}
              >
                {score}
              </TableCell>
            );
          })
        }
      </TableRow>
    );
  }

  getTable = (taskType: string = 'primary', mainAuxiliary: boolean = true) => {
    if (this.props.resultList.results.length === 0) {
      return (<div />);
    }
    const tableHeader = this.getHeader(taskType, true);

    const tableRows = (
      <TableBody>
        {
          this.props.resultList.results.map((result: UserResultType, key) => {
            const maxRow = this.getRow(taskType, result, result.maxScoreSubmission,
              true, key);
            const rows: JSX.Element[] = result.otherSubmissions.map(
              (submission, submissionKey: number) => {
                return this.getRow(taskType, result,
                  submission, false, key, mainAuxiliary);
              }
            );

            rows.unshift(maxRow);
            return rows;
          })
        }
      </TableBody>
    );

    return (
      <Table
        style={tableStyle}
      >
        {tableHeader}
        {tableRows}
      </Table>
    );
  }

  getTableTabs = () => {
    return (
      <div>
        <Snackbar
          autoHideDuration={30000}
          open={true}
          message={'Click on a submission to see more information'}
          style={{ height: 'auto', lineHeight: '28px', padding: 12, whiteSpace: 'pre-line' }}
        />
        {this.getTable('primary', true)}
        <br />
        <br />
      </div>
    );
  }

  getLeaderboardVersion = () => {
    return (
      <Typography variant="h5">
        Leaderboard Version: <b>{this.props.metadata.version}</b>
      </Typography>
    )
  }

  getDiagnostics = (submission: ResultType | null) => {
    if (!submission) {
      return {};
    }
    const diagnostics: any = {};

    Object.keys(submission.tasks).forEach((id) => {
      const task = this.props.taskList.tasks[id];
      const taskScore = { ...submission.tasks[id], name: task.name };

      if (task.type === 'auxiliary') {
        if (task.auxiliaryType === 'main') {
          if (!diagnostics[task.identifier]) {
            diagnostics[task.identifier] = {};
          }
          diagnostics[task.identifier].main = taskScore;
        } else if (task.auxiliaryType === 'category') {
          let main = task.identifier.substr(0, 2);
          if (main === 'AX') {
            main = 'AX-b';
          }
          if (!diagnostics[main]) {
            diagnostics[main] = {};
          }

          if (!diagnostics[main].categories) {
            diagnostics[main].categories = {};
          }

          if (!diagnostics[main].categories[task.identifier]) {
            diagnostics[main].categories[task.identifier] = {};
          }

          diagnostics[main].categories[task.identifier].main = taskScore;
        } else if (task.auxiliaryType === 'subcategory') {
          let main = task.identifier.substr(0, 2);
          if (main === 'AX') {
            main = 'AX-b';
          }
          if (!diagnostics[main]) {
            diagnostics[main] = {};
          }

          if (!diagnostics[main].categories) {
            diagnostics[main].categories = {};
          }

          const category = task.identifier.substr(0, 4);
          if (!diagnostics[main].categories[category]) {
            diagnostics[main].categories[category] = {};
          }

          if (!diagnostics[main].categories[category].categories) {
            diagnostics[main].categories[category].categories = {};
          }
          diagnostics[main].categories[category].categories[task.identifier] = taskScore;
        }
      }
    });

    return diagnostics;
  }

  getDiagnosticsList = (diagnostics: any) => {
    return (
      Object.keys(diagnostics).map((id) => {
        if (id !== 'AX-b') {
          return '';
        }
        const topScore = diagnostics[id];
        const confusion = getPrintableConfusion(JSON.parse(topScore.main.confusion));
        const html = (
          <ListItem className={this.props.classes.diagnosticsListItem}>
            <ListItemText>{topScore.main.name} confusion matrix</ListItemText>
            <ListItemText style={{ display: "inline-block" }}>
              <table className="diagnostics-table">
                <thead>
                  <tr><th /><th>N</th><th>E</th></tr>
                </thead>
                <tbody>
                  {
                    confusion.split('\n')
                      .map(c => <tr key={randomString()} dangerouslySetInnerHTML={{ __html: c }} />)
                  }
                </tbody>
              </table>
            </ListItemText>
            <ListItemText>N = not entailment</ListItemText>
            <ListItemText>E = entailment</ListItemText>
          </ListItem>
        );

        if (!topScore.categories) {
          topScore.categories = {};
        }

        const categories = Object.keys(topScore.categories).map((categoryId) => {
          const categoryScore = topScore.categories[categoryId];

          if (!categoryScore.categories) {
            categoryScore.categories = {};
          }

          const subCategoriesScores = Object.keys(categoryScore.categories).map((subId) => {
            const subCategoryScore = categoryScore.categories[subId];
            const name = subCategoryScore.name.substr(categoryScore.main.name.length).trim().replace(/-/g, '');
            return (
              <ListItem
                className={this.props.classes.diagnosticsListItem}
                key={randomString()}
              >
                {name + ': ' + getRoundedScore(subCategoryScore.score)}
              </ListItem>
            );
          });

          const categoryHtml = (
            <ListItem
              className={this.props.classes.diagnosticsListItem}
              key={randomString()}
            >
              {categoryScore.main.name + ': ' + getRoundedScore(categoryScore.main.score)}
              {subCategoriesScores}
            </ListItem>
          );

          return categoryHtml;
        });

        return (
          <div key={randomString()}>
            {html}
            <Divider />
            <b><ListItem>Category-wise Matthew's Correlation Scores</ListItem></b>
            {categories}
            <Divider />
          </div>
        );
      })
    );
  }

  getDrawerContent = () => {
    const submission = this.state.submission;
    if (!submission) {
      return <div />;
    }
    const diagnostics = this.getDiagnostics(submission);

    return (
      <List style={{ textAlign: 'left' }}>
        <b><ListItem>{submission.editable.name}</ListItem></b>
        <ListItem>GitHub/Model URL: &nbsp;
         {submission.editable.url ? (
            <a href={submission.editable.url}>
              <Icon className="fa fa-external-link" style={{ ...fontIconUrlStyle, fontSize: '1em' }} />
            </a>
          ) : 'N/A'}
        </ListItem>
        <ListItem>Submitted:&nbsp;{timeSince(submission.createdOn)}</ListItem>
        <ListItem>Score:&nbsp;<b>{getRoundedScore(submission.macroScore)}</b></ListItem>
        <ListItem
        // style={{color: STRING_CONSTANTS.THEME[this.props.general.theme].BLUE}}
        >
          <Link to={'submission/' + submission.uploaderUid + '/' + submission.submissionId}>
            More details &nbsp;
          <Icon className="fa fa-external-link" style={{ ...fontIconUrlStyle, fontSize: '1em' }} />
          </Link>
        </ListItem>
        <Divider />
        <b><ListItem>Model Description</ListItem></b>
        <ListItem>{submission.editable.modelDescription}</ListItem>
        <Divider />
        <b><ListItem>Parameter Description</ListItem></b>
        <ListItem>{submission.editable.parameterDescription}</ListItem>
        <Divider />
        <b><ListItem>Parameter Information</ListItem></b>
        <ListItem>
          Shared w/o fine-tuning:&nbsp;{submission.editable.woFineTuningParameters}
        </ListItem>
        <ListItem>
          Fine-tuned:&nbsp;{submission.editable.fineTunedParameters}
        </ListItem>
        <ListItem>Task Specific: &nbsp;{submission.editable.taskSpecificParameters}</ListItem>
        <Divider />
        <ListItem><b>Diagnostics Information</b></ListItem>
        {this.getDiagnosticsList(diagnostics)}
      </List>
    );
  }

  render() {
    if (!this.props.userInfo.isSiteReady) {
      return <NotReady />;
    }
    const StyleDrawer = withStyles(drawerStyles)(Drawer);
    return (
      <div>
        {
          (this.props.resultList.isRequested || this.props.taskList.isRequested) ?
            (<div style={overflowHidden}>
              <p>Fetching leaderboard...</p>
              <img src={loading} className="App-logo" alt="Loading" />
            </div>) :

            (
              <div>
                <StyleDrawer
                  anchor="right"
                  className="leaderboard-drawer"
                  open={this.state.drawerOpen}
                  onClose={() => this.toggleDrawer(false)}
                >
                  {this.getDrawerContent()}
                </StyleDrawer>
                {this.getLeaderboardVersion()}
                <br />
                {this.getTableTabs()}
              </div>
            )
        }
      </div>
    );
  }
}

export default withStyles(leaderboardStyles)(Leaderboard);
