import { StoreState } from '../types/index';
import { auth } from '../firebase';

export const userInfoDefault: StoreState['userInfo'] = {
  isAuthenticated: !!auth.currentUser,
  uid: (auth.currentUser ? auth.currentUser.uid : null),
  redirectToReferrer: false,
  displayName: (auth.currentUser ? auth.currentUser.displayName : null),
  email: (auth.currentUser ? auth.currentUser.email : null),
  avatarImageUrl: (auth.currentUser ? auth.currentUser.photoURL : null),
  isAdmin: false,
  isBanned: false,
  isCheckingLogin: true,
  isFetchingSubmissions: false,
  submissions: {},
  otherSubmissions: {},
  isSiteReady: true
};

export const taskListDefault: StoreState['taskList'] = {
  isRequested: false,
  tasks: {}
};

export const submissionDefault: StoreState['submission'] = {
  isChecking: false,
  canUpload: false
};

export const resultListDefault: StoreState['resultList'] = {
  isRequested: false,
  selectedUser: -1,
  selectedUserOpen: false,
  results: []
};

export const generalDefault: StoreState['general'] = {
  theme: 'LIGHT'
};

export const metadataDefault: StoreState['metadata'] = {
  version: null,
  isRequested: false
}