import LoginBase from '../components/LoginBase';
import { StoreState } from '../types/index';
import { Dispatch, AnyAction } from 'redux';
import { connect } from 'react-redux';
import { finishLoginWithGoogle } from '../actions/index';
import firebase from 'firebase';

const mapStateToProps = (state: StoreState) => ({
  userInfo: state.userInfo,
  general: state.general
});

type PossibleActions = AnyAction;
const mapDispatchToProps = (dispatch: any) => ({
  dispatchFinishLoginWithGoogle: (result: firebase.User) => {
    dispatch(finishLoginWithGoogle(result));
  }
});

const Login = connect(mapStateToProps, mapDispatchToProps)(LoginBase);

export default Login;
